.home-iframe-main-container{
    width: 100%;
    height: 100vh;
}

.plai-i-frame{
    padding: 0;
    margin: 0;
    display: block;
    border: none;
    width: 100%;
    height: 100%;
  }